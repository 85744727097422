
.carousel-image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.carousel-container {
    position: relative;
    margin: auto;
}

.carousel-item {
    width: 100%;
    height: 100%;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
}
.prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.carousel-caption-bottom {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.carousel-caption-center {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    top: 45%;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.carousel-caption-top {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 8px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.dots {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}
.slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active, .dot:hover {
    background-color: #717171;
}

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 5s;
    animation-name: fade;
    animation-duration: 5s;
}
.pause-icon {
    position: absolute;
     /*top: 38%;*/
    left: 45%;
    bottom: 2px;
     /*right: 50%;*/
    opacity: 1;
}

.pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 0.5s;
    animation-name: pause;
    animation-duration: 0.5s;
}

.bar {
    width: 100%;
    background-color: #ddd;
    position: absolute;
    bottom: 0px;
    border-radius: 0 0 10px;
}

.progress {
    width: 1%;
    height: 5px;
    background-color: #042baa;
}

.thumbnails {
    display: flex;
    margin-top: 10px;
    align-items: center;
    overflow: scroll;
}
.thumbnails::-webkit-scrollbar {
    display: none;
}
.thumbnail {
    margin: 0 5px;
}
.active-thumbnail {
    border: #476ff1 solid 3px;
}
@keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1; }

}
@-webkit-keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1;}

}
@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@media only screen and (max-width: 500px) {
    .prev,
    .next,
    .carousel-caption-bottom,
    .carousel-caption-center,
    .carousel-caption-top,
    .slide-number
    {
        font-size: 20px !important;
    }
    .dot {
        height: 4px;
        width: 4px;
    }
    .carousel-container{
        max-height: 250px !important;
    }
    .thumbnail {
        max-width: 70px;
    }
    .carousel-caption-bottom{
        bottom: 25px;
    }
}

/* ==========================================================================
    Slider Styling
   ========================================================================== */
.site-slider {
    /*padding-top: 102px;*/
}

.site-slider ul li {
    float: left;
    margin: 0;
}

.bx-wrapper img {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.bx-wrapper {
    background-color: #161616;
}

.bx-thumbnail-wrapper {
    background-color: #161616;
    text-align: center;
    margin-top: -1px;
    position: relative;
}

.caption-wrapper {
    position: relative;
}

.slider-caption {
    position: absolute;
    z-index: 988;
    bottom: 140px;
    background-color: white;
    display: inline-block;
    right: 0;
}

.slider-caption h2 {
    font-size: 30px;
    margin: 20px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
}

#bx-pager a {
    margin: 20px 10px 0 20px;
    padding: 0 0 40px 0;
    display: inline-block;
    opacity: 0.4;
    filter: alpha(opacity=40);
    outline: 0;
    border: 0;
}

#bx-pager a img {
    outline: 0;
    border: 0;
}

#bx-pager a.active {
    opacity: 1;
    filter: alpha(opacity=100);
    background-position: center 85px;
}

.bx-controls {
    position: relative;
}

.bx-controls-direction {
    position: absolute;
    text-align: right;
    bottom: 80px;
    right: -2px;
    z-index: 998;
}

.bx-controls-direction a {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
    float: left;
    margin-right: 2px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: white;
}