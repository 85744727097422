.navbar-logo {

}

.navbar-logo-img {
    padding-right: 14px
}

@font-face {
    font-family: myFirstFont;
    src: url('/public/fonts/ISOCT2.ttf');
}

.navbar-brand-name {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 2.5rem;
}

.navbar-section-title {
    /*border-top: 1px solid #e1e6e9;*/
    /*margin-top: 60px;*/
    margin-left: 10px;
    /*padding-top: 60px;*/
    /*padding-bottom: 60px;*/
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'myFirstFont', sans-serif;
}

.navbar-section-title-big {
    font-size: 30px;
}

.site-header {
    position: fixed;
    /*min-height: 102px;*/
    /*height: 102px;*/
    width: 100%;
    z-index: 9999;
    background-color: #ffffff;
}

.navbar-collapse > .navbar-nav > .active,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {

    color: #26b864;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
    background-color: unset;
    color: #26b864;
}

.navbar-collapse {
    background-color: white;
}

.active-nav {
    background-color: #26b864;
}

